import React, { useContext } from 'react';
import { withTheme } from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';

import UserDataWrapper, { EditableContext } from './UserDataWrapper';
import PrivateRoute from '../../components1/PrivateRoute';
import { Text } from '../../components1/Texts';
import FlexDiv from '../../components1/FlexDiv';

import { Link } from './components/links';
import {
    ActivateCurrentWeekOrderButton, SuspendCurrentWeekOrderButton,
    ActivateSubscriptionButton, SuspendSubscriptionButton,
} from './components/buttons';

import { userList, customerList, subscriberList } from './data/accounts';

const Account = (props) => {
    const { currentWeekOrderId } = useContext(EditableContext);
    const {
        theme, isLogged, authUserData, user,
        subscriptionModalRef, updateSubscriptionDeactivate,
        updateSubscriptionReactivate,
        isLastSubscriptionsDeactivate, areAllSubscriptionsDeactivate,
        subscriptionReactivateCurrentWeekOrder, subscriptionDeactivateCurrentWeekOrder,
        deleteOrderById,
    } = props;
    const menuList = authUserData.isCustomer ? customerList : userList;
    return (
        <StaticQuery
            query={
                graphql`
                    query {
                        habitude: file(relativePath: { eq: "concept/concept-habitude.jpg" }) {
                            ...fullWidthImage
                        }
                    }
                `
            }
            render={data => (
                <FlexDiv
                    style={{
                        backgroundColor: theme.color.beigeFaible,
                        flexDirection: 'column',
                        alignItems: 'stretch'
                    }}
                >
                    <Text
                        style={{
                            fontSize: '2.25rem',
                            textAlign: 'center',
                            fontFamily: theme.font.title,
                            fontWeight: 600,
                            padding: '60px 0 30px 0',
                        }}
                        css={`
                            @media only screen and (max-width:599px) {
                                font-size: 1.5rem !important;
                            }
                        `}
                    >
                        Je souhaite :
                    </Text>
                    <FlexDiv
                        style={{
                            justifyContent: 'space-around',
                            paddingBottom: 60,
                        }}
                        css={`
                            @media only screen and (max-width:599px) {
                                flex-direction: column !important;
                                align-items: center;
                                flex-basis: 20rem;
                                padding-bottom: 0 !important;
                            }
                        `}
                    >
                        {
                            menuList.map((l, index) => <Link l={l} index={index} theme={theme} editable={!!currentWeekOrderId} />)
                        }
                    </FlexDiv>
                    {
                        user.isSubscriber && (
                            <FlexDiv
                                style={{
                                    justifyContent: 'space-around',
                                    paddingBottom: 60,
                                }}
                                css={`
                                    @media only screen and (max-width:599px) {
                                        flex-direction: column !important;
                                        align-items: center;
                                        flex-basis: 13.3rem;
                                    }
                                `}
                            >
                                {
                                    [
                                        isLastSubscriptionsDeactivate ?
                                            <ActivateCurrentWeekOrderButton
                                                subscriptionReactivateCurrentWeekOrder={subscriptionReactivateCurrentWeekOrder}
                                                page="myAccount"
                                                data={subscriberList[0][0]}
                                            /> :
                                            <SuspendCurrentWeekOrderButton
                                                deleteOrderById={deleteOrderById}
                                                subscriptionDeactivateCurrentWeekOrder={subscriptionDeactivateCurrentWeekOrder}
                                                page="myAccount"
                                                data={subscriberList[0][1]}
                                            />,

                                        areAllSubscriptionsDeactivate ?
                                            <ActivateSubscriptionButton
                                                updateSubscriptionReactivate={updateSubscriptionReactivate}
                                                page="myAccount"
                                                data={subscriberList[1][0]}
                                            /> :
                                            <SuspendSubscriptionButton
                                                deleteOrderById={deleteOrderById}
                                                updateSubscriptionDeactivate={updateSubscriptionDeactivate}
                                                page="myAccount"
                                                data={subscriberList[1][1]}
                                            />
                                    ]
                                }
                            </FlexDiv>
                        )
                    }
                    <Image fluid={data.habitude.childImageSharp.fluid} alt="habitude"/>
                </FlexDiv>
            )}
        />
    )
};

export default PrivateRoute(
    // connect(mapStateToProps)(withTheme(UserDataWrapper(Account)))
    withTheme(UserDataWrapper(Account))
);
