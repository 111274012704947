import React from 'react';

import { GatsbyLink, TextResponsive } from '../../../components1/layoutFolder/SubHeaderTabs';

export const Link = ({ l, index, theme, editable }) => (
    <GatsbyLink
        key={index.toString()}
        to={l.page}
        style={{
            textDecoration: 'none',
            // whiteSpace: 'nowrap',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // height: 35,
            color: theme.color[l.color],
            backgroundImage: `url(${l.parentheses})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%',
            width: '15rem',
            height: '5rem',
        }}
    >
        <TextResponsive
            style={{
                padding: 10,
                textAlign: 'center',
                fontFamily: theme.font.title,
                fontSize: '1.25rem',
                fontWeight: 600,
            }}
        >
            {
                !editable ?
                    l.name.map(n => (<>{n}<br /></>)) :
                    l.editableName.map(n => (<>{n}<br /></>))
            }
        </TextResponsive>
    </GatsbyLink>
);
