import React from 'react';
import { Layout } from '../../components1/layout';

import MyAccount from '../../containers/user/MyAccount';

const UserAccountPage = () => {
    return (
        <Layout isUserPage>
            {
                () => (
                    <MyAccount />
                )
            }
        </Layout>
    );
};

export default UserAccountPage;
