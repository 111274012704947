import {
    parenthesesVerticalRoses, parenthesesVerticalVertes,
    parenthesesVerticalNoires, parenthesesVerticalJaunes
} from '../../../assets';

export const userList = [
    {
        name: ['Commander', 'mon premier', 'CABADICI'],
        page: '/user/cabadici',
        color: 'rougeFaible',
        parentheses: parenthesesVerticalRoses,
    },
    {
        name: ['Modifier mes ', 'coordonnées', 'personnelles / bancaires'],
        page: '/user/informations',
        color: 'vertFort',
        parentheses: parenthesesVerticalVertes,
    },
];

export const customerList = [
    {
        name: ['Choisir', 'mon prochain', 'CABADICI'],
        editableName: ['Modifier', 'mon prochain', 'CABADICI'],
        page: '/user/cabadici',
        color: 'rougeFaible',
        parentheses: parenthesesVerticalRoses,
    },
    {
        name: ['Modifier mes ', 'coordonnées', 'personnelles / bancaires'],
        editableName: ['Modifier mes ', 'coordonnées', 'personnelles / bancaires'],
        page: '/user/informations',
        color: 'vertFort',
        parentheses: parenthesesVerticalVertes,
    },
    {
        name: ['Voir mes commandes', 'passées'],
        editableName: ['Voir mes commandes', 'passées'],
        page: '/user/orders',
        color: 'jauneFort',
        parentheses: parenthesesVerticalJaunes,
    },
];

export const subscriberList = [
    [
        {
            name: ['Réactiver ma ', 'prochaine livraison'],
            page: '/user/cabadici',
            color: 'noirFort',
            parentheses: parenthesesVerticalNoires,
        },
        {
            name: ['Suspendre ma ', 'prochaine livraison'],
            page: '/user/cabadici',
            color: 'noirFort',
            parentheses: parenthesesVerticalNoires,
        },
    ],
    [
        {
            name: ['Réactiver mon', 'abonnement'],
            page: '/user/cabadici',
            color: 'noirFort',
            parentheses: parenthesesVerticalNoires,
        },
        {
            name: ['Désactiver mon', 'abonnement'],
            page: '/user/cabadici',
            color: 'noirFort',
            parentheses: parenthesesVerticalNoires,
        },
    ]
];
